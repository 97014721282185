import React, { useContext, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { Rating } from "@mui/material";
import UploadFile from "../Files/UploadFile";
import { useMutation, useQuery } from "react-query";
import { getProfileDetails, updateProfileDetails } from "../../lib/userprofile-api";
import { getSkillList } from "../../lib/dropDownList-api";
import { AuthContext } from "../../context/AuthContext";
import { updateResumeFile, uploadResumeFile } from "../../lib/onboarding-api";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import PreferredPlaceDropDown from "../Select/PreferredPlaceDropDown";
import CurrencyCode from "../Select/CurrencyCode";
import {
  noticePeriodMenu,
  experienceData,
  workPlaceTypeOptions,
  employmentTypeOptions,
} from "../../common/constants";
import MultipleSelectChip from "../Select/MultiSelect";
import AsyncSelectWrapper from "../Select/MultiSelectAsync";
import Button from "../Button/Button";
import BackButton from "../Button/OutlinedButton";
import ErrorDialog from "../Dialog/ErrorDialog";
import LinearProgress from "@mui/material/LinearProgress";
import { getOptionsToRender } from "../../common/common";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Yup from "yup";
const UploadResumeForm = ({ handleClose }) => {
  const boxStyle = {
    width: "100%",
    maxWidth: "480px",
    height: "120px",
  };
  const style = { text: " text-sm mb-1" };
  const [skillOptions, setSkillOptions] = useState([]);
  const [condition, setCondition] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [errorDialog, setErrorDialog] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [message, setMessage] = useState("");
  const [showMandatoryFields, setShowMandatoryFields] = useState(false);
  const { t } = useTranslation();
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const { mutate: uploadResumeAPI, isLoading: isUploadResumeLoading } =
    useMutation(uploadResumeFile);
  const { mutate: updateResumeAPI, isLoading: isUpdateResumeLoading } =
    useMutation(updateResumeFile);
  const { mutate: updateDetails, isLoading: isUpdateDetailsLoading } =
    useMutation(updateProfileDetails);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { isLoading } = useQuery(["get rate skills 1"], getSkillList, {
    onSuccess: (data) => {
      let skillOptions = data?.data?.data?.map((skill) => {
        return {
          value: skill.skill_name,
          label: skill.skill_name,
        };
      });

      setSkillOptions(skillOptions);
    },
  });
  const { data: profileData, refetch } = useQuery(
    ["/profileDetails", userId],
    getProfileDetails,
    {
      refetchOnWindowFocus: false,
      enabled: userId !== "undefined" ? true : false,
      onSuccess: (data) => {
        if (data?.data?.data?.profile_information?.resume_files_id) {
          handleDisableNext(false);
        } else {
          handleDisableNext(true);
        }
      },
    }
  );
  const uploadResumeAPICall = (values) => {
    let reqData = {
      resume: values.resume,
      id: userId,
    };
    uploadResumeAPI(
      { reqData },
      {
        onSuccess: (data) => {
          refetch();
        },
        onError: (error) => {
          setErrorDialog(true);
          setMessage(error?.response?.data?.message || error?.response?.statusText);
        },
      }
    );
  };
  const updateResumeAPICall = (values) => {
    let reqData = {
      resume: values.resume,
      id: userId,
      fileId: profileData?.data?.data?.profile_information.resume_files_id,
    };
    updateResumeAPI(
      { reqData },
      {
        onSuccess: (data) => {
          refetch();
        },
        onError: (error) => {
          setErrorDialog(true);
          setMessage(error?.response?.data?.message || error?.response?.statusText);
        },
      }
    );
  };

  const handleDisableNext = (e) => {
    setDisableNext(e);
  };
  const validate = Yup.object({
    skills: Yup.array().of(
      Yup.object().shape({
        skillName: Yup.string().required(t("_Skill_is_required")),
        skillLevel: Yup.string().required(t("_Level_is_required")),
      })
    ),
    noticePeriod: Yup.string().required(t("_Notice_Period_is_required")),

    workPlace: Yup.array()
      .min(1, t("_Minimum_1_Character_Required"))
      .required(t("_Work_place_is_required")),
    employmentType: Yup.string().required(t("_Employment_type_is_required")),
    currentCtc: Yup.number()
      .typeError("Only numbers allowed")
      .required(t("_Current_ctc_is_required"))
      .max(9999999999, t("_Max_10_digits_allowed")),

    expectedCTC: Yup.number()
      .typeError("Only numbers allowed")
      .required(t("_Expected_ctc_is_required"))
      .max(9999999999, t("_Max_10_digits_allowed")),

    preferredPlaceToRelocate: Yup.array().of(
      Yup.object().shape({
        country: Yup.string(),
        states: Yup.array()
          .min(1, t("_Preferred_Location_is_required"))
          .max(5, t("_Max_allowed_is_5")),
      })
    ),
  });

  return (
    <>
      <Formik
        initialValues={{
          resume: "",
          skills: [
            {
              skillName: "",
              skillLevel: "",
            },
            {
              skillName: "",
              skillLevel: "",
            },
            {
              skillName: "",
              skillLevel: "",
            },
            {
              skillName: "",
              skillLevel: "",
            },
            {
              skillName: "",
              skillLevel: "",
            },
          ],
          preferredPlaceToRelocate: [{ country: "IN", states: [] }],
          showMandatoryFields: false,
          currentCtcCurrency: {
            label: profileData?.data.data?.more_aboutyou?.current_currency || "INR",
            value: profileData?.data.data?.more_aboutyou?.current_currency || "INR",
          },
          currentCtc: profileData?.data.data?.more_aboutyou?.current_ctc || "",
          expectedCTC: profileData?.data.data?.more_aboutyou?.expected_ctc || "",
          expectedCTCCurrency: {
            label: profileData?.data.data?.more_aboutyou?.expected_currency || "INR",
            value: profileData?.data.data?.more_aboutyou?.expected_currency || "INR",
          },
          noticePeriod: profileData?.data?.data?.location_interest.notice_period || "",
          workPlace: profileData?.data?.data?.location_interest.preferred_work_mode || "",
          employmentType:
            profileData?.data?.data?.location_interest.preferred_employment_type?.[0] ||
            "",
        }}
        validationSchema={showMandatoryFields ? validate : ""}
        onSubmit={(values, { setFieldValue, setTouched }) => {
          if (!showMandatoryFields) {
            if (values?.resume) {
              if (
                profileData?.data?.data?.profile_information.resume_file !== "" ||
                profileData?.data?.data?.profile_information.resume_files_id !== undefined
              ) {
                updateResumeAPICall(values);
                setFieldValue("resume", "");
              } else {
                uploadResumeAPICall(values);
                setFieldValue("resume", "");
              }
              setFieldValue("showMandatoryFields", true);
              setShowMandatoryFields(true);
              setTouched({});
            } else if (profileData?.data?.data?.profile_information.resume_file) {
              handleDisableNext(false);
              setFieldValue("showMandatoryFields", true);
              setShowMandatoryFields(true);
              setTouched({});

              //navigate("/auth/quick-resume");
            }
          } else {
            const payload = {
              completed_screen_id: 1,
              rateyour_skills: {
                skill_proficiency: values?.skills?.map((skill) => {
                  return {
                    skill_name: skill.skillName,
                    skill_level: skill.skillLevel.toString(),
                  };
                }),
              },

              location_interest: {
                preferred_location: values?.preferredPlaceToRelocate,
                notice_period: values?.noticePeriod,
                preferred_work_mode: values?.workPlace,
                preferred_employment_type: [values?.employmentType],
              },
              more_aboutyou: {
                ctc_details: {
                  current_currency: values?.currentCtcCurrency.value,
                  current_ctc: values?.currentCtc,
                  expected_currency: values?.expectedCTCCurrency.value,
                  expected_ctc: values?.expectedCTC,
                },
              },
            };
            updateDetails(
              { userId, payload },
              {
                onSuccess: () => {
                  // navigate("/auth/quick-resume");
                  handleClose();
                  refetch();
                },
                onError: (error) => {
                  setErrorDialog(true);
                  setMessage(error?.response?.data?.message);
                },
              }
            );
          }
        }}
      >
        {({
          errors,
          isValid,
          setFieldValue,
          values,
          handleBlur,
          handleChange,
          touched,
          setTouched,
        }) => (
          <Form>
            {console.log(touched)}
            {/* {console.log(values)} */}
            {!showMandatoryFields ? (
              <div className="">
                <div className="mb-5 text-sm text-heading font-semibold">
                  {" "}
                  {t("_Upload_Resume")}
                </div>
                {profileData?.data?.data?.profile_information?.resume_file ? (
                  <div className="mb-5">
                    <p className="text-xs  text-title mt-5">
                      {t("_We_have_your_Resume")} -
                      <span className="underline text-secondary cursor-pointer ">
                        {profileData?.data?.data?.profile_information?.resume_file}
                      </span>
                      {" from "}
                      <span className="text-grayTextColor">
                        {" "}
                        {dayjs(
                          profileData?.data?.data?.profile_information?.resume_upload_date
                        ).format("DD-MM-YYYY")}
                      </span>
                    </p>
                    <p className="text-xs  text-grayTextColor text-center my-5">
                      {t("Or")}
                    </p>
                    <p className="text-xs  text-title  my-5">
                      {t("_You_can_upload_your_updated_resume_below")}
                    </p>
                  </div>
                ) : null}
                <div className="mb-5">
                  <UploadFile
                    handleDisableNext={handleDisableNext}
                    setCondition={setCondition}
                    setFieldValue={setFieldValue}
                    name={"resume"}
                    isLoading={isUpdateResumeLoading || isUploadResumeLoading}
                    value={values.resume}
                    boxStyle={boxStyle}
                  />
                </div>
                <Button
                  buttonClassName="mt-2 bg-bgPrimary text-white text-xs font-normal  w-full py-3 rounded-lg h-dropdownHeight"
                  block="true"
                  type="submit"
                  value="submit"
                  onClick={() => {
                    setTouched({});
                  }}
                  disabled={isUpdateResumeLoading || isUploadResumeLoading || disableNext}
                  fullWidth
                  label={t("_NEXT")}
                />
              </div>
            ) : (
              <div>
                {isUpdateResumeLoading || isUploadResumeLoading ? (
                  <div className="mt-8 text-medium text-primary">
                    {t("_Uploading_your_resume")}
                    <LinearProgress variant="indeterminate" className="mt-3 mb-3 " />
                  </div>
                ) : message ? (
                  <div className="text-error-icon text-xs">
                    {"Upload failed!.Please try again."}
                  </div>
                ) : (
                  <div className="text-sm"> {t("_Resume_uploaded_successfully")}</div>
                )}
                <p className="text-sm">
                  Fill in the below detail and get noticed easily by the recruiters!
                </p>
                {/* {console.log(values)} */}
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                  className="mt-7"
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }} className="text-sm">
                      {t("_Skills")}
                      <span className="text-error-icon">*</span>
                    </Typography>
                    <Typography className="text-small text-tertiary">
                      Get accurate recommendations! Enter your top 5 Key skills and your
                      expertise level.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {/* <div className="text-small text-tertiary">
                        Get accurate recommendations! Enter your top 5 Key skills and your
                        expertise level.
                      </div> */}
                      {Array(5)
                        .fill()
                        .map((_, index) => (
                          <div key={index} className="flex gap-4 mb-4">
                            <div className="w-4/5">
                              <AsyncSelectWrapper
                                className="w-full h-[35px] text-primary rounded-[5px] placeholder-inputColorSecondary border-solid border-borderSecondary font-semibold "
                                isMulti={false}
                                isLoading={isLoading}
                                optionsData={getOptionsToRender(
                                  values.skills,
                                  skillOptions,
                                  index
                                )}
                                placeholder={t("_Select_skill")}
                                isSearchable={true}
                                name={`skills[${index}].skillName`}
                                isClearable={false}
                              />
                            </div>
                            <div className="w-3/5">
                              <Rating
                                name={`skills[${index}].skillLevel`}
                                value={values?.skills?.[index]?.skillLevel}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              ></Rating>
                              <ErrorMessage
                                component="div"
                                className={"absolute  text-[#d32f2f] text-[11px]"}
                                name={`skills[${index}].skillLevel`}
                              />
                            </div>
                          </div>
                        ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }} className="text-sm">
                      Preferences
                    </Typography>
                    {/* <Typography sx={{ color: "text.secondary" }}>
                      You are currently not an owner
                    </Typography> */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className=" flex flex-col  lg:grid lg:grid-cols-2 gap-x-5 gap-y-3">
                        <div>
                          <div className={`${style.text} `}>
                            {t("_Notice_Period")}
                            <span className="text-error-icon">*</span>
                          </div>
                          <MultipleSelectChip
                            className=" w-full h-[35px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
                            options={noticePeriodMenu}
                            isMulti={false}
                            placeholder={t("_Select_Notice_Period")}
                            isSearchable={true}
                            name="noticePeriod"
                            isClearable={false}
                          />
                        </div>
                        <div>
                          <div className={`${style.text} `}>
                            {t("_Employment_type")}
                            <span className="text-error-icon">*</span>
                          </div>

                          <MultipleSelectChip
                            className=" w-full h-[35px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
                            options={employmentTypeOptions}
                            isMulti={false}
                            placeholder={t("_Select_Employment_Type")}
                            isSearchable={true}
                            name="employmentType"
                            isClearable={false}
                          />
                        </div>
                        <div>
                          <div className={`${style.text} mt-2 `}>
                            {t("_Current_CTC")}
                            <span className="text-error-icon">*</span>
                          </div>
                          <CurrencyCode
                            values={values}
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // options={options}
                            fieldName="currentCtc"
                            currencyFieldName={"currentCtcCurrency"}
                          />
                          <ErrorMessage
                            component="div"
                            className="text-[#d32f2f] absolute text-xs"
                            name={"currentCtc"}
                          />
                        </div>
                        <div>
                          <div className={`${style.text} mt-2`}>
                            {t("_Expected_CTC")}
                            <span className="text-error-icon">*</span>
                          </div>
                          <CurrencyCode
                            values={values}
                            setFieldValue={setFieldValue}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // options={options}
                            fieldName="expectedCTC"
                            currencyFieldName={"expectedCTCCurrency"}
                          />
                          <ErrorMessage
                            component="div"
                            className="text-[#d32f2f] absolute text-xs"
                            name={"expectedCTC"}
                          />
                        </div>

                        <div className="col-span-2">
                          <div className={`${style.text} `}>
                            {t("_Preferred_work_mode")}
                            <span className="text-error-icon">*</span>
                          </div>

                          <MultipleSelectChip
                            className=" w-full h-[35px] text-primary  rounded-[5px] placeholder-inputColorSecondary  border-solid border-borderSecondary font-semibold "
                            options={workPlaceTypeOptions}
                            isMulti={true}
                            placeholder={t("_Select_Work_Mode")}
                            isSearchable={true}
                            name="workPlace"
                            isClearable={false}
                          />
                        </div>
                        <div className="col-span-2">
                          <div className={`${style.text} mt-2`}>
                            {t("_Preferred_Locations")}
                            <span className="text-error-icon">*</span>
                          </div>
                          <PreferredPlaceDropDown
                            index={0}
                            placeholder={t("_Select_location")}
                            value={values.preferredPlaceToRelocate}
                            setFieldValue={setFieldValue}
                            name="preferredPlaceToRelocate"
                            maxSelectedValue={5}
                          ></PreferredPlaceDropDown>
                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <div className="flex flex-row gap-x-5 mb-5">
                  <Button
                    buttonClassName="mt-5  text-xs font-normal  w-1/2 py-3 rounded-lg h-dropdownHeight"
                    block="true"
                    type="submit"
                    value="submit"
                    disabled={isUpdateResumeLoading || isUploadResumeLoading}
                    fullWidth
                    label={
                      isUpdateResumeLoading || isUploadResumeLoading
                        ? "Uploading file.."
                        : t("_SUBMIT")
                    }
                  />

                  <BackButton
                    buttonClassName="mt-5 text-xs font-normal  w-1/2 py-3 rounded-lg h-dropdownHeight"
                    block="true"
                    value="submit"
                    disabled={isUpdateResumeLoading || isUploadResumeLoading}
                    fullWidth
                    label={t("_BACK")}
                    onClick={() => {
                      refetch();
                      setShowMandatoryFields(false);
                    }}
                  />
                </div>
              </div>
            )}
            {errorDialog ? (
              <ErrorDialog
                message={
                  message || t("_Sorry!_Something_went_wrong._Please_try_again_later")
                }
                open={errorDialog}
                onClick={() => setErrorDialog(false)}
              ></ErrorDialog>
            ) : null}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UploadResumeForm;
