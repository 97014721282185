import { useContext, useState } from "react";
import { CloseIcon, WorkIcon, EmployeeIcon } from "../icons";
import SaveButton from "../components/Button/OutlinedButton";
import ApplyButton from "../components/Button/Button";
import { useTranslation } from "react-i18next";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { applyJob, getJobDetail, saveJob } from "../lib/jobs-api";
import { getProfileDetails } from "../lib/userprofile-api";
import { AuthContext } from "../context/AuthContext";
import ErrorDialog from "../components/Dialog/ErrorDialog";
import SuccessDialog from "../components/Dialog/SuccessDialog";
import ApplyConfirmation from "../components/Dialog/ApplyConfirmation";
import dayjs from "dayjs";
import { calculateValidDays } from "../common/common";
import { jobStatus, templates } from "../common/constants";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { Chip } from "@mui/material";
import { employmentTypeOptions, workPlaceTypeOptions } from "../common/constants";
import { ResumeUploadModelContext } from "../context/ProfileDetailsContext";

function JobDescription({ selectedJob, onClose, isOpenSearch }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { userId, template } = useContext(AuthContext);
  const { isResumeUploadModelOpen, setIsResumeUploadModelOpen, handleResumeModalClose } =
    useContext(ResumeUploadModelContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [subContent, setSubContent] = useState("");
  const [video, setVideo] = useState("");
  const [pic, setPic] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    [`JobDeatils/${selectedJob.job_id}`, selectedJob.job_id, userId],
    getJobDetail
  );

  const { mutate: applyJobApi } = useMutation(applyJob);
  const { mutate: saveJobApi } = useMutation(saveJob);

  const handleOnClose = () => {
    onClose();
  };
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    // navigate("/app/profile/profile-information", {
    //   state: {
    //     job: selectedJob,
    //   },
    // });
    navigate("/app/profile/profile-information");
    setOpenDialog(false);
  };

  const handleErrorDialogOpen = () => {
    setErrorDialogOpen(true);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const { data: profileDetails } = useQuery(
    ["/profile Details", userId],

    getProfileDetails,
    {
      onSuccess: (response) => {
        const video = response?.data?.data?.profile_information?.intro_video_file_name;
        // console.log("Profile details:", video);
        setVideo(video);
        const pic = response?.data?.data?.profile_information?.profile_pic_file?.length;
        // console.log("Profile details:", pic);
        setPic(pic);
      },
    }
  );
  const validTillDate = dayjs(selectedJob?.valid_till);
  const currentDate = dayjs();
  const validity = validTillDate.diff(currentDate, "day");

  //console.log("123selectedJob", selectedJob, selectedJob.job_status);
  const getLabelByValue = (value, options) => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : "";
  };

  const getEmploymentTypeLabel = (value) => getLabelByValue(value, employmentTypeOptions);
  const getWorkModeLabel = (value) => getLabelByValue(value, workPlaceTypeOptions);

  return (
    <div className="mt-6 md:ml-6 md:mr-10">
      <div className="flex md:flex-row items-center mt-6">
        {/* <div className="text-secondary text-xl font-medium">{selectedJob?.job_title}</div> */}
        <CloseIcon
          className="h-3 w-3 ml-auto mr-3 text-tertiary cursor-pointer"
          onClick={handleOnClose}
        />
      </div>
      <div className="grid grid-cols-4 md:grid-cols-12 p-5 whitespace-nowrap">
        <img
          src={selectedJob?.company_logo}
          alt="Company Logo"
          className="w-[50px] h-[50px]  col-span-1"
        />

        <div className="col-span-3 ml-5 ">
          <div className="text-secondary text-medium font-medium my-1">
            {" "}
            {data?.data?.job_title}
          </div>
          <div className="text-title text-xs my-1 font-medium">
            {" "}
            {data?.data?.company}
          </div>
          <div className="text-tertiary text-xs font-medium my-1">
            {data?.data?.company_website}
          </div>
          <div className="text-title text-xs font-medium">
            {data?.data?.city?.toString()}
          </div>
        </div>

        <div className="col-span-3  flex items-center pt-3 md:pt-0 md:pb-1 md:ml-24 ">
          <div className="text-heading text-[20px] -mt-2">.</div>
          <div className="text-tertiary text-[12px] font-medium ml-1">
            {t("_applicants", { count: data?.data?.number_of_jobs })}
          </div>
        </div>
        <div className="col-span-2 my-auto flex items-center pt-3 md:pt-0 md:pb-1 md:ml-28">
          <div className="text-heading text-[20px] -mt-2">.</div>
          <div className="text-tertiary text-[12px] font-medium ml-1">
            {t("_Open_till_days", {
              count: calculateValidDays(selectedJob?.valid_till),
            })}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-row md:mt-3">
        <WorkIcon className="md:ml-[80px] ml-0" />
        <div className="ml-2 mt-1 text-title text-xs font-medium">
          {selectedJob?.emp_type}
        </div>
        <EmployeeIcon className="ml-9" />
        <div className="ml-2 mt-1 text-title text-[12px] font-medium">
          {selectedJob?.company_info?.total_employees}
        </div>
      </div> */}
      <div className="  ltr:ml-[75px] rtl:mr-[75px] flex gap-2 flex-wrap">
        {isLoading ? (
          <Skeleton />
        ) : (
          data?.data?.primary_skills
            ?.concat(data?.data?.secondary_skills)
            .map((primary_skill, index) => {
              return (
                <Chip
                  key={index}
                  className="bg-bgPrimary text-white text-xs rounded-full "
                  label={primary_skill.skill_name}
                />
              );
            })
        )}
      </div>
      <div className="mt-8">
        <div className="text-title text-sm font-medium mb-1">
          {t("_Company_description")}
        </div>
        <div className=" mt-5 ">
          {data?.data?.company_desc && !isLoading ? (
            <div className="text-title text-[12px] font-medium mb-1 ">
              <p>{data?.data?.company_desc}</p>
            </div>
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </>
          )}
        </div>
      </div>
      <div className="mt-8">
        <div className="text-title text-sm font-medium mb-1">{t("_Job_Description")}</div>
        <div className=" mt-5 ">
          {data?.data?.job_desc && !isLoading ? (
            <div className="text-title text-[12px] font-medium mb-1 ">
              {data?.data?.job_desc?.split("\n").map((str, index) => (
                <p key={index}>{str}</p>
              ))}
            </div>
          ) : (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </>
          )}
        </div>
      </div>
      {data?.data?.domain && data?.data?.domain !== "" ? (
        <div className="mt-8">
          <div className="text-title text-sm font-medium mb-1">
            {isLoading ? (
              <div className="flex gap-5">
                {t("_Domain")}:
                <Skeleton width={"20%"} />
              </div>
            ) : (
              <>
                {t("_Domain")}
                <span className="text-title text-[12px] font-medium mb-2 capitalize">
                  : {data?.data?.domain}
                </span>
              </>
            )}
          </div>
        </div>
      ) : null}
      {data?.data?.emp_type !== "" ? (
        <div className="mt-8">
          <div className="text-title text-sm font-medium mb-1">
            {isLoading ? (
              <div className="flex gap-5">
                {t("_Employment_type")}:
                <Skeleton width={"20%"} />
              </div>
            ) : (
              <>
                {t("_Employment_type")}
                <span className="text-title text-[12px] font-medium mb-2">
                  : {getEmploymentTypeLabel(data?.data?.emp_type)}
                </span>
              </>
            )}
          </div>
        </div>
      ) : null}
      {data?.data?.work_env !== "" ? (
        <div className="mt-8">
          <div className="text-title text-sm font-medium mb-1">
            {isLoading ? (
              <div className="flex gap-5">
                {t("_Workplace_type")}:
                <Skeleton width={"20%"} />
              </div>
            ) : (
              <>
                {t("_Workplace_type")}
                <span className="text-title text-[12px] font-medium mb-2">
                  : {getWorkModeLabel(data?.data?.work_env)}
                </span>
              </>
            )}
          </div>
        </div>
      ) : null}
      {data?.data?.min_exp_years !== "" &&
      data?.data?.max_exp_years !== "" &&
      data?.data?.max_exp_years !== "" ? (
        <div className="mt-8">
          <div className="text-title text-sm font-medium mb-1">
            {isLoading ? (
              <div className="flex gap-5">
                {t("_Workplace_type")}:
                <Skeleton width={"20%"} />
              </div>
            ) : (
              <>
                {t("_Experience")}
                <span className="text-title text-[12px] font-medium mb-2">
                  :{" "}
                  {data?.data?.min_exp_years === 0
                    ? t("_Fresher")
                    : data?.data?.min_exp_years === 1
                    ? `${data?.data?.min_exp_years} ${t("_Year")}`
                    : `${data?.data?.min_exp_years} ${t("_Years")}`}
                  {data?.data?.min_exp_years
                    ? ` - ${
                        data?.data?.max_exp_years === 1
                          ? `${data?.data?.max_exp_years} ${t("_Year")}`
                          : `${data?.data?.max_exp_years} ${t("_Years")}`
                      }`
                    : ""}
                </span>
              </>
            )}
          </div>
        </div>
      ) : null}
      {data?.data?.qualification !== "" &&
      data?.data?.qualification !== undefined &&
      data?.data?.qualification !== null ? (
        <div className="mt-8">
          <div className="text-title text-sm font-medium mb-1">
            {isLoading ? (
              <div className="flex gap-5">
                {t("_Qualification")}:
                <Skeleton width={"20%"} />
              </div>
            ) : (
              <>
                {t("_Qualification")}
                <span className="text-title text-[12px] font-medium mb-2 ">
                  : {data?.data?.qualification?.toString()}
                </span>
              </>
            )}
          </div>
        </div>
      ) : null}

      <div className="mt-8 flex flex-wrap gap-5">
        {template === templates.platformAdmin ||
        data?.data?.is_saved === jobStatus.saved ||
        data?.data?.job_status ? null : (
          <div>
            {console.log("isSaved", selectedJob?.is_saved)}
            <SaveButton
              buttonClassName="w-full md:w-[145px] h-dropdownHeight text-xs"
              // buttonClassName="w-full md:w-[200px] h-[60px] md:max-w-[200px]"
              label={selectedJob?.is_saved === jobStatus.saved ? "_UNSAVE" : "_SAVE"}
              onClick={() => {
                saveJobApi(
                  {
                    userId: userId,
                    job_id: selectedJob?.job_id,
                    mark: selectedJob?.is_saved === 1 ? 0 : 1,
                    score: selectedJob?.score,
                  },
                  {
                    onSuccess: (response) => {
                      refetch();
                      queryClient.invalidateQueries("recommendedjobsList");
                      setSuccessDialog(true);
                      setMessage(response.data.message);
                    },
                    onError: (error) => {
                      setErrorDialogOpen(true);
                      setMessage(error?.response?.data?.message);
                    },
                  }
                );
              }}
            />
          </div>
        )}
        {/* {console.log("Job Status before refetch:", selectedJob?.job_status)} */}
        {template === templates.platformAdmin ||
        data?.data?.job_status === jobStatus.applied ? null : (
          <div>
            <ApplyButton
              type="button"
              label={t("_APPLY")}
              buttonClassName="w-full md:w-[145px] h-dropdownHeight text-xs"
              // buttonClassName=" w-full md:w-[200px] h-[60px] md:max-w-[200px] md:-mt-0 "
              onClick={() => {
                //if (video !== "" && pic !== 0) {
                if (
                  Object.keys(profileDetails?.data?.data?.rateyour_skills).length !== 0
                ) {
                  applyJobApi(
                    {
                      userId,
                      job_id: selectedJob?.job_id,
                      score: isOpenSearch ? 0 : selectedJob?.score,
                    },
                    {
                      onSuccess: () => {
                        refetch();
                        // console.log("Job Status after refetch:", data?.data?.job_status);
                        if (video !== "" && pic !== 0) {
                          setSuccessDialog(true);
                        } else {
                          setOpenDialog(true);
                        }
                        setMessage("_Job_Applied_Successfully.");
                        setSubContent(
                          "_Profiles_with_candidate_photo_and_video_have_excellent_chances_of_getting_selected_for_the_interview_process._We_recommend_you_to_upload_your_picture_and_complete_the_video_to_improve_your_chances_of_getting_a_call_back."
                        );

                        // handleDialogOpen();
                      },
                      onError: (error) => {
                        setErrorDialogOpen(true);
                        setMessage(error?.response?.data?.message);
                      },
                    }
                  );
                } else {
                  setIsResumeUploadModelOpen(true);
                }
                // } else {
                //   handleDialogOpen();
                //   setMessage(
                //     t(
                //       "_Profiles_with_candidate_photo_and_video_have_excellent_chances_of_getting_selected_for_the_interview_process._We_recommend_you_to_upload_your_picture_and_complete_the_video_to_apply_for_this_job."
                //     )
                //   );
                // }
              }}
              // onClick={handleDialogOpen}
            ></ApplyButton>
          </div>
        )}
      </div>
      {/* <Assessment open={openDialog} handleClose={handleDialogClose} /> */}
      <ApplyConfirmation
        message={message}
        subContent={subContent}
        open={openDialog}
        handleProfile={handleDialogClose}
        onClose={() => {
          setOpenDialog(false);
          // applyJobApi(
          //   {
          //     userId,
          //     job_id: selectedJob?.job_id,
          //     score: isOpenSearch ? 0 : selectedJob?.score,
          //   },
          //   {
          //     onSuccess: () => {
          //       refetch();
          //       // console.log("Job Status after refetch:", data?.data?.job_status);
          //       setSuccessDialog(true);
          //       setMessage("_Applied_Successfully");

          //       // handleDialogOpen();
          //     },
          //     onError: (error) => {
          //       setErrorDialogOpen(true);
          //       setMessage(error?.response?.data?.message);
          //     },
          //   }
          // );
        }}
      />
      <ErrorDialog
        open={errorDialogOpen}
        message={message}
        onClick={handleErrorDialogClose}
      />
      <SuccessDialog
        message={message}
        open={successDialog}
        onOkayClick={() => {
          // refetch();
          setSuccessDialog(false);
        }}
      />
    </div>
  );
}

export default JobDescription;
