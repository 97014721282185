//import React from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import ReactSelect, { components, createFilter } from "react-select";
import { CheckmarkIcon } from "../../icons";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
const Select = ({ fontSize, minHeight, quickResume, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { setFieldValue, setFieldTouched, handleBlur } = useFormikContext();
  const { template } = useContext(AuthContext);
  const customSelectStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      fontWeight: "500",
      fontSize: fontSize || "12px",
      minHeight: "40px",
      fontSize: fontSize || "12px",
      minHeight: "40px",
      borderWidth: "1px",
      borderColor: state.isFocused
        ? "#2D2D2D"
        : meta.touched && meta.error
        ? "#d32f2f"
        : "#70707059",
      borderRadius: "0.3rem",
      // paddingTop: "7px",
      // paddingBottom: "7px",
      //borderRadius: template === templates.recruiter ? "0.3rem" : "0.75rem",
      "&:hover": {
        border: "2px solid #2D2D2D",
      },

      // This line disable the blue border
      boxShadow: "none",
    }),
    option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
      ...provided,
      color: "#2D2D2D",
      background: "white",
      ":hover": {
        background: "#7070701C",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: fontSize || "12px",
      fontWeight: "500",
      color: "#7070708C",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "white",
      fontSize: fontSize || "12px",
      position: "absolute",
      //The below width line is commented because it makes entire for scrollable
      //width: "max-content",
      minWidth: "100%",
      // border: "0 !important",
      border: "1px solid #70707059",
      boxShadow: "0 !important",
      "&:hover": {
        border: "1px solid #70707059",
      },
    }),
    multiValueRemove: (base) => ({
      ...base,
      backgroundColor: "#0071B6",
      color: "white",
      borderTopRightRadius: "20px",
      borderBottomRightRadius: "20px",
      // height: "30px",

      "&:hover": {
        backgroundColor: "#0071B6",
        color: "white",
      },
    }),
    multiValueLabel: (data) => ({
      ...data,
      height: "25px",
      padding: "2px 3px",
      fontSize: "12px",
      marginTop: "1.5px",
      color: "white !important",
    }),
    multiValue: (data) => ({
      ...data,
      height: "25px",
      padding: "0px 6px",
      backgroundColor: "#0071B6",
      borderRadius: "20px",
      fontSize: "12px",
    }),
    input: (data) => ({
      ...data,

      "input:focus": {
        boxShadow: "none",
      },
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
  };

  const IconOption = (props) => (
    <components.Option {...props} className="flex justify-between">
      {props.data.label}
      {props.isSelected && <CheckmarkIcon className="text-secondary" />}
    </components.Option>
  );

  return (
    <div className="relative">
      <ReactSelect
        aria-label={props.name}
        className={`input ${props.className} ${
          meta.touched && meta.error && "is-danger"
        }`}
        options={props.options}
        isSearchable={props.isSearchable || false}
        styles={customSelectStyles}
        isMulti={props.isMulti}
        type="text"
        filterOption={createFilter({ ignoreAccents: false })}
        isClearable={props.isClearable}
        maxMenuHeight={props.maxMenuHeight}
        name={props.name}
        blurInputOnSelect={handleBlur}
        isOptionDisabled={() =>
          props.isOptionDisabled ? field.value.length >= props.isOptionDisabled : null
        }
        defaultValue={props.defaultValue}
        isDisabled={props.disabled}
        isLoading={props.isLoading}
        value={
          !props.isMulti
            ? props.options
              ? props.options.find((option) => option.value === field.value)
              : ""
            : props.options.filter((option) => field?.value?.indexOf(option.value) >= 0)
        }
        onChange={(option) => {
          setFieldTouched(props.name, true);
          if (props.onChange) props.onChange(option);
          if (!props.isMulti) {
            helpers.setValue(option);
            setFieldValue(props.name, option.value);
          } else {
            let options = option.map((option) => option.value);
            setFieldValue(props.name, options);
          }
        }}
        placeholder={props.placeholder}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
      ></ReactSelect>
      <ErrorMessage
        component="div"
        className={`absolute ${
          quickResume ? "text-resumeTextColor" : "text-[#d32f2f]"
        } text-[11px] ${props.name === "industry" ? "mt-0" : "mt-1"}`}
        name={field.name}
      />
    </div>
  );
};

export default Select;
