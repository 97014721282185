import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";

import Box from "@mui/material/Box";
import CustomDropdownList from "../Dropdown/CustomDropdownList";
import OutlinedButton from "../Button/OutlinedButton";
import Button from "../Button/Button";
import { CloseIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { companyOptions, filterTypes, onSiteRemoteOptions } from "../../common/constants";
import InputLabel from "../FormsUI/InputLabel";
import { getCompanyList, getIndustryList } from "../../lib/dropDownList-api";
import { Country, State } from "country-state-city";
import { useQuery } from "react-query";
import { Form, Formik } from "formik";
import JobLocation from "../../components/Select/PreferredPlaceDropDown";
const JobsFilter = ({
  open,
  handleClose,
  filters,
  filterType,
  onApply,
  onReset,
  onFilterUpdate,
}) => {
  const { t } = useTranslation();
  // const [industryList, setIndustryList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  //const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedWorkMode, setSelectedWorkMode] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const formikRef = React.useRef(null);
  const handleReset = () => {
    //formikRef.current.resetForm();
    setResetKey((prevKey) => prevKey + 1);
    onReset();
    setSelectedLocations([
      {
        country: "IN",
        states: [],
      },
    ]);
  };
  let countryOptions = Country.getAllCountries();
  let countryName = countryOptions.map((country) => {
    return {
      value: country.isoCode,
      label: country.name,
    };
  });

  const { isLoading: isCompanyListLoading } = useQuery(["/companyList"], getCompanyList, {
    onSuccess: (res) => {
      const companyMenuList = res.data.data.map((company) => {
        return { value: company?.company_name, label: company.company_name };
      });
      setCompanyList(companyMenuList);
    },
  });

  // const { isLoading: isIndustryListLoading } = useQuery(
  //   ["/IndustryList"],
  //   getIndustryList,
  //   {
  //     onSuccess: (res) => {
  //       const industryMenuList = res.data.data.map((industry) => {
  //         return { value: industry.industry_name, label: industry.industry_name };
  //       });
  //       setIndustryList(industryMenuList);
  //     },
  //   }
  // );

  return (
    <Box width={"100%"}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              margin: 0,
            },
          },
        }}
      >
        <div className="mx-5">
          <div className=" mt-5 text-title text-sm font-medium flex items-center justify-between">
            {t("_Filters")}
            <CloseIcon onClick={handleClose} className="text-tertiary w-3 h-3" />
          </div>

          <div className="mt-3  mb-3 border-b border-tertiary" />

          <div className="mb-5">
            <InputLabel label={t("_Company")} color={"text-title"} />
            <CustomDropdownList
              btnWidth={"w-full"}
              data={companyList}
              isLoading={isCompanyListLoading}
              btnName={t("_Select_company")}
              filterType={filterTypes.companyList}
              reset={resetKey}
              defaultSelectedData={selectedCompany}
              onSelectionChange={(selectedCompany) => {
                console.log(selectedCompany);
                setSelectedCompany(selectedCompany);
              }}
            />
          </div>
          {/* <div className="mb-5">
            <InputLabel label={t("_Location")} color={"text-title"} />
            <CustomDropdownList
              btnWidth={"w-full"}
              data={countryName}
              isLoading={false}
              btnName={t("_Select_location")}
              filterType={filterTypes.location}
              reset={resetKey}
              onSelectionChange={(selectedLocation) => {
                setSelectedLocation(selectedLocation);
              }}
            />
          </div> */}
          <div className="w-full">
            <Formik
              initialValues={{
                jobLocation: [
                  {
                    country: selectedLocations[0]?.country || "IN",
                    states: selectedLocations[0]?.states || [],
                  },
                ],
              }}
              innerRef={formikRef}
              onSubmit={(values) => {
                //console.log("Form submitted");
              }}
              validate={(data) => setSelectedLocations(data.jobLocation)}
              validateOnChange={true}
              validateOnBlur={true}
              enableReinitialize
            >
              {({ setFieldValue, values, isValid, errors }) => (
                <Form className="mb-5">
                  <InputLabel label={t("_Location")} color={"text-title"} />
                  <JobLocation
                    //fontSize={"12px"}
                    height={"30px"}
                    index={0}
                    value={selectedLocations}
                    setFieldValue={setFieldValue}
                    name="jobLocation"
                    isMandatory={true}
                    reportFilter={true}
                  ></JobLocation>
                </Form>
              )}
            </Formik>
          </div>
          <div className="mb-5">
            <InputLabel label={t("_Workplace_type")} color={"text-title"} />
            <CustomDropdownList
              btnWidth={"w-full"}
              data={onSiteRemoteOptions}
              isLoading={false}
              btnName={t("_Select")}
              filterType={filterTypes.workLocation}
              reset={resetKey}
              isSearch={false}
              defaultSelectedData={selectedWorkMode}
              onSelectionChange={(selectedWorkMode) => {
                setSelectedWorkMode(selectedWorkMode);
              }}
            />
          </div>
          {/* <div className="mb-8">
                <InputLabel label={t("_Industry")} color={"text-title"} />
                <CustomDropdownList
                  btnWidth={"w-full"}
                  data={industryList}
                  isLoading={isIndustryListLoading}
                  btnName={t("_Select")}
                  filterType={filterTypes.industry}
                  reset={resetKey}
                  onSelectionChange={(selectedIndustry) => {
                    setSelectedIndustry(selectedIndustry);
                  }}
                />
              </div> */}
          <hr className="my-6 text-tertiary"></hr>

          <div className="flex gap-5 mb-7">
            <Button
              type="submit"
              buttonClassName="w-24 h-9 px-10 py-4 text-xs"
              label="_APPLY"
              onClick={() => {
                console.log("selectedCompany", selectedCompany);
                if (selectedCompany) {
                  onApply(filterTypes.companyList, selectedCompany);
                }
                if (selectedLocations && selectedLocations.length > 0) {
                  onApply(filterTypes.location, selectedLocations);
                }
                if (selectedWorkMode) {
                  onApply(filterTypes.workLocation, selectedWorkMode);
                }
                if (selectedIndustry) {
                  onApply(filterTypes.industry, selectedIndustry);
                }
                handleClose();
              }}
            />

            <OutlinedButton
              type="button"
              size="small"
              onClick={() => {
                handleReset();
              }}
              buttonClassName="w-24 h-9 px-10 py-4 mr-9 text-xs"
              label={"_RESET"}
            />
          </div>
        </div>
      </Dialog>
    </Box>
  );
};

export default JobsFilter;
