/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
  {
    path: "/app/profile/personal-information",
    icon: "",
    name: "_Personal_information",
  },
  {
    path: "/app/profile/account-settings",
    icon: "",
    name: "_Account_settings",
  },
  {
    path: "/app/profile/education-profile",
    icon: "",
    name: "_Education",
  },
  {
    path: "/app/profile/work-experience",
    icon: "",
    name: "_Work_experience",
  },
  {
    path: "/app/profile/rate-your-skills",
    icon: "",
    name: "_Rate_your_skills",
  },
  {
    path: "/app/profile/profile-information",
    icon: "",
    name: "_Profile_information",
  },
  {
    path: "/app/profile/location-interests",
    icon: "",
    name: "_Location_&_Interests",
  },
  {
    path: "/app/profile/more-about-you",
    icon: "",
    name: "_CTC_and_Summary",
  },
  {
    path: "/app/profile/notification",
    icon: "",
    name: "_Notification",
  }
];

export default routes;
