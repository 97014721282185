import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ExistingUserUploadResume from "../pages/ExistingUserUploadResume";
import QuickResume from "../pages/QuickResume";
const SignUp = lazy(() => import("../pages/SignUp"));
const Login = lazy(() => import("../pages/Login"));
const LinkedinSuccess = lazy(() => import("../pages/LinkedInSuccess"));
const LinkdinError = lazy(() => import("../pages/LinkdinError"));
const CreatePassword = lazy(() => import("../pages/CreatePassword"));
const ForgetUsername = lazy(() => import("../pages/ForgetUsername"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const Success = lazy(() => import("../pages/Success"));
const OtpVerification = lazy(() => import("../pages/OtpVerification"));
const CreateCuratalAccount = lazy(() => import("../pages/CreateCuratalAccount"));
const EnterDetails = lazy(() => import("../pages/EnterDetails"));
const VerifyAccount = lazy(() => import("../pages/VerifyAccount"));

const SignIn = lazy(() => import("../pages/Recruiter/SignIn"));
const RecruiterSignUp = lazy(() => import("../pages/Recruiter/SignUp"));
const ContactSales = lazy(() => import("../pages/Recruiter/ContactSales"));
const SubscriptionPlan = lazy(() => import("../pages/Recruiter/SubscriptionPlan"));
const SignUpSuccess = lazy(() => import("../pages/Recruiter/SignupSucess"));

const PcaLogin = lazy(() => import("../pages/PcaAdmin/Login"));

function Auth() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-username" element={<ForgetUsername />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/forgot-password/:user" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/otp-verification" element={<OtpVerification />} />

      <Route path="/resume" element={<ExistingUserUploadResume />} />
      <Route path="/quick-resume" element={<QuickResume />} />

      <Route path="/signup" element={<SignUp />} />
      <Route path="/create" element={<CreateCuratalAccount />} />
      <Route path="/password" element={<CreatePassword />} />
      <Route path="/success" element={<Success />} />
      <Route path="/enter-details" element={<EnterDetails />} />
      <Route path="/signup-success" element={<VerifyAccount />} />
      <Route path="/contact-sales" element={<ContactSales />} />
      <Route path="/linkedin/success" element={<LinkedinSuccess />} />
      <Route path="/linkedin/error" element={<LinkdinError />} />

      <Route path="/" element={<Navigate to="/auth/login" />} />
      <Route path="/*" element={<Navigate to="/auth/login" />} />

      {/* Recruiter */}
      <Route path="/recruiter/login" element={<SignIn />} />
      <Route path="/recruiter/signup" element={<RecruiterSignUp />} />
      <Route path="/recruiter/signup-success" element={<SignUpSuccess />} />
      <Route path="/recruiter/subscription" element={<SubscriptionPlan />} />
      <Route path="/recruiter/*" element={<Navigate to="/auth/recruiter/login" />} />

      {/* PCA */}
      <Route path="/pca/login" element={<PcaLogin />} />
    </Routes>
  );
}

export default Auth;
