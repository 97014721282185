import axios from "axios";
import { axiosPrivate } from "../common/axiosPrivate";
import getAPIMap from "../routes/ApiUrls";
const IP = process.env.REACT_APP_IP;

/*
 * This method is used to get skills score
 */
export async function getSkillScore(options) {
  let url = getAPIMap("skillScore");
  url = url.replace("{id}", options.queryKey[1]);

  let response = axiosPrivate.get(url);
  return response;
}

/*
 * This method is used to get skills score
 */
export async function getTopRecruiters(options) {
  let url = getAPIMap("topRecruiters");
  url = url.replace("{userid}", options.queryKey[1]);
  let response = axiosPrivate.get(url);
  return response;
}

/*
 * This method is used for invite jobs
 */
export async function getInviteJobs(options) {
  let url = getAPIMap("inviteJobs");
  url = url.replace("{candidateid}", options.queryKey[1]);
  if (options.queryKey[2]) {
    url = url + `?page=${options.queryKey[2]}`;
  }
  if (options.queryKey[3]) {
    url = url + `&pagesize=${options.queryKey[3]}`;
  }
  // let url = `https://curatal-dev.openturf.dev/curatal_recruiter//api/v1/accounts/invited_jobs/${candidateId}`;
  let response = await axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to get top industries
 */
export async function getTopIndustries() {
  let url = getAPIMap("topIndustries");
  let response = await axiosPrivate.get(url);
  return response;
}

/*
 * This method is used for accept jobs
 */
export async function acceptInvite(options) {
  //const candidateId = options.candidateId;
  let url = getAPIMap("acceptJobs");

  //let url = `https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/recruiter/candidate/acceptinvite/{candidateid}`;
  url = url.replace("{candidateid}", options.candidateId);
  let response = await axiosPrivate.put(url, options.payload);
  //console.log("candidateid", options.candidateId);
  return response;
}

/*
 * This method is used for listing assessed skills
 */
export async function getAssessedSkills(options) {
  let url = getAPIMap("getAssessedSkills");

  url = url.replace("{candidateId}", options.queryKey[1]);
  let response = await axiosPrivate.get(url);

  return response;
}

/*
 * This method is used for listing assessed skills
 */
export async function getSpiderGraph(options) {
  let url = getAPIMap("getSpiderGraph");

  let response = await axiosPrivate.get(url);

  return response;
}
/*
 * This method is used for Candidate status in dashboard
 */
export async function candidateStatusApiMethod(options) {
  
  const payload = { job_search_status: String(options) };
  let url = getAPIMap("candidateStatusApi",{});
  let response = axiosPrivate.post(url,payload);
  return response;
}
